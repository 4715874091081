import React from "react"
import propTypes from "prop-types"

import PhoneIcon from "../assets/icons/phone.svg"
import "./phoneButton.scss"

import { Button } from "react-bootstrap"
const phoneButton = ({ phoneNumber }) => {
  return (
    <Button
      className="phone-button"
      as="a"
      href={`tel:${phoneNumber}`}
      variant="primary"
    >
      <PhoneIcon />
    </Button>
  )
}

phoneButton.propTypes = {
  phoneNumber: propTypes.string,
}

phoneButton.defaultProps = {
  phoneNumber: ``,
}

export default phoneButton
