import React from "react"
import { Link } from "gatsby"
import { NavDropdown } from "react-bootstrap"

import { languageSwitcher } from "../data/links"

const LanguageSwitcher = ({ locale, location }) => {
  return (
    <NavDropdown
      title={languageSwitcher.dropDownTitle[locale]}
      id="basic-nav-dropdown"
    >
      <NavDropdown.Item as={`li`}>
        <Link to="/" hrefLang="de">
          {languageSwitcher.german[locale]}
        </Link>
      </NavDropdown.Item>
      <NavDropdown.Item as={`li`}>
        <Link to="/en" hrefLang="en">
          {languageSwitcher.english[locale]}
        </Link>
      </NavDropdown.Item>
    </NavDropdown>
  )
}

export default LanguageSwitcher
