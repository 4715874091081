import React from "react"
import PropTypes from "prop-types"

import LocalizedLink from "./ LocalizedLink"
import Logo from "../assets/icons/logo-with-white-text.svg"

import { links } from "../data/links"
import { Container, Row, Col } from "react-bootstrap"
import CookieConsent from "react-cookie-consent"

const cookieText = {
  de: {
    button: `Ok`,
    text: `Diese Webseite muss Cookies nutzen, um zu funktionieren. Wir versuchen, nur die Nötigsten einzusetzen. Durch die weitere Nutzung der Webseite stimmst du der Verwendung von Cookies entsprechend unserer Datenschutzerklärung zu.`,
  },
  en: {
    button: `Ok`,
    text: `This website must use cookies in order to function. We try to use only the bare minimum. By continuing to use the website, you consent to the use of cookies in accordance with our privacy policy.`,
  },
}
const Footer = ({ locale, phoneNumber }) => {
  return (
    <footer className="footer pt-4 pb-4">
      <Container>
        <Row>
          <Col
            className="d-flex align-items-center justify-content-center"
            md={4}
          >
            <Logo />
          </Col>
          <Col
            md={8}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="d-flex justify-content-center flex-column">
              {links
                .filter(x => x.isInFooter)
                .map(link => {
                  return (
                    <LocalizedLink
                      className="mr-4"
                      key={link.label[locale]}
                      to={link.url}
                    >
                      {link.label[locale]}
                    </LocalizedLink>
                  )
                })}
            </div>
          </Col>
        </Row>
        <CookieConsent
          location="bottom"
          buttonText={cookieText[locale].button}
          cookieName="umzug-mountain"
          style={{ background: `#061b2d` }}
          buttonStyle={{
            color: `#fff`,
            fontSize: `16px`,
            backgroundColor: `#a7c01b`,
            borderRadius: `0.25rem`,
          }}
          expires={150}
        >
          {cookieText[locale].text}
        </CookieConsent>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  locale: PropTypes.string,
  phoneNumber: PropTypes.string,
}

Footer.defaultProps = {
  locale: `de`,
  phoneNumber: ``,
}

export default Footer
