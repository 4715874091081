module.exports = {
  en: {
    default: false,
    path: `en`,
    locale: `en`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
  },
  de: {
    default: true,
    path: `de`,
    locale: `de`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `de`,
  },
}
