import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "../assets/scss/App.scss"
import Footer from "./footer"
import PhoneButton from "./phoneButton"
export const LocaleContext = React.createContext()

const Layout = ({ children, pageContext: { locale }, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      contentfulGeneralInfo(name: { eq: "general" }) {
        email
        phoneNumber
        mobileNumber
      }
    }
  `)

  return (
    <LocaleContext.Provider value={{ locale }}>
      <Header
        phoneNumber={data.contentfulGeneralInfo.phoneNumber}
        mobileNumber={data.contentfulGeneralInfo.mobileNumber}
        locale={locale}
        location={location}
        siteTitle={data.site.siteMetadata.title}
      />
      <div>
        <main className="main-section">{children}</main>
      </div>
      <PhoneButton phoneNumber={data.contentfulGeneralInfo.phoneNumber} />
      <Footer locale={locale} />
    </LocaleContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
