export const links = [
  {
    label: { de: `Startseite`, en: `Home` },
    url: `/`,
    isInNavbar: true,
    isInFooter: false,
  },
  {
    label: { de: `Leistungen`, en: `Services` },
    url: `/services`,
    isInNavbar: true,
    isInFooter: false,
  },
  {
    label: { de: `Kontakt`, en: `Contact` },
    url: `/contact`,
    isInNavbar: true,
    isInFooter: true,
  },
  {
    label: { de: `Datenschutzerklaerung`, en: `Privacy` },
    url: `/privacy`,
    isInNavbar: false,
    isInFooter: true,
  },
  {
    label: { de: `Impressum`, en: `Imprint` },
    url: `/imprint`,
    isInNavbar: false,
    isInFooter: true,
  },
]

export const languageSwitcher = {
  dropDownTitle: { de: `Sprache`, en: `Language` },
  english: { de: `Englisch`, en: `English` },
  german: { de: `Deutsch`, en: `German` },
}
