import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import { Navbar, Nav, Container } from "react-bootstrap"

import { links } from "../data/links"
import LocalizedLink from "./ LocalizedLink"
import LanguageSwitcher from "./LanguageSwitcher"
import Logo from "../assets/icons/logo-white-arrow.svg"

import PhoneIcon from "../assets/icons/phone.svg"
import Smartphone from "../assets/icons/smartphone.svg"

import "./header.scss"

const Header = ({ siteTitle, locale, location, phoneNumber, mobileNumber }) => {
  const [expanded, setExpanded] = useState(false)

  const handleNavClose = e => {
    if (e.target.localName !== `a`) {
      if (expanded) {
        setExpanded(false)
      }
    }
  }

  useEffect(() => {
    document.addEventListener(`click`, handleNavClose)
    return () => {
      document.removeEventListener(`click`, handleNavClose)
    }
  })
  return (
    <Navbar
      id="navbar"
      expanded={expanded}
      fixed="top"
      onToggle={() => setExpanded(!expanded)}
      bg="dark"
      variant="dark"
      expand="lg"
    >
      <Container>
        <Navbar.Brand as="a" className="text-uppercase font-weight-bold">
          <Logo />
          {siteTitle}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            {links
              .filter(x => x.isInNavbar)
              .map(link => {
                return (
                  <Nav.Link key={link.label[locale]} as={`div`}>
                    <LocalizedLink to={link.url}>
                      {link.label[locale]}
                    </LocalizedLink>
                  </Nav.Link>
                )
              })}
            <Nav.Link
              href="https://g.co/kgs/yusWBq"
              target="_blank"
              rel="noopener noreferrer"
            >
              {locale === `de` ? `Bewertungen` : `Reviews`}
            </Nav.Link>
            <Nav.Link className="primary-text" href={`tel:${phoneNumber}`}>
              <PhoneIcon />
              {phoneNumber}
            </Nav.Link>
            <Nav.Link className="primary-text" href={`tel:${mobileNumber}`}>
              <Smartphone />
              {mobileNumber}
            </Nav.Link>
            <LanguageSwitcher locale={locale} location={location} />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  locale: PropTypes.string,
  phoneNumber: PropTypes.string,
  mobileNumber: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  locale: `de`,
  phoneNumber: ``,
  mobileNumber: ``,
}

export default Header
